import React from 'react';
import { Alert } from 'react-bootstrap';
import { Chart } from 'react-chartjs-2';
import prettyBytes from 'pretty-bytes';
import createColormap from 'colormap';
import humanizeDuration from 'humanize-duration';
import { getContrast } from '../Util';

const colors = createColormap({ colormap: 'viridis', nshades: 101, format: 'hex' });

function colorFromRaw(ctx, data, colorBy) {
  if (ctx.type !== 'data') {
    return 'transparent';
  }

  const max = Math.max.apply(Math, data.children.map((x) => x[colorBy]));
  const value = Math.ceil((ctx.raw._data[colorBy] / max) * 100);
  return colors[value];
}

export default function TreeGraph(props) {
  const {
    path, data, scaleBy, colorBy, chartRef, onTileClick,
  } = props;

  const chartData = {
    datasets: [
      {
        label: data.path,
        tree: data.children,
        key: scaleBy,
        spacing: 0,
        labels: {
          display: true,
          formatter(ctx) {
            if (ctx.type !== 'data') {
              return;
            }
            return [ctx.raw._data.path, prettyBytes(ctx.raw._data.size), `${(ctx.raw._data.count - 1).toLocaleString()} children`];
          },
          color(ctx) {
            if (ctx.type !== 'data') {
              return;
            }
            const color = ctx.element.options.backgroundColor;
            if (typeof (color) === 'string') {
              return getContrast(ctx.element.options.backgroundColor);
            }
          },
          overflow: 'fit',
        },
        backgroundColor: (ctx) => colorFromRaw(ctx, data, colorBy),
        borderColor: 'grey',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    animation: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title(items) {
            return items[0].element.$context.raw._data.path;
          },
          label(item) {
            return [
              `Size: ${prettyBytes(item.raw._data.size)}`,
              `Bytes: ${item.raw._data.size.toLocaleString()}`,
              `Total Children: ${(item.raw._data.count - 1).toLocaleString()}`,
              `Indexed Children: ${(item.raw._data.es_doc_count - 1).toLocaleString()}`,
              // `Mean Heat: ${humanizeDuration(item.raw._data.mean_heat, { round: true })}`,
            ];
          },
        },
      },
    },
  };

  let theChart = (
    <Alert variant="danger">
      {path}
      {' '}
      does not exist.
    </Alert>
  );
  if (data.children.length > 0) {
    theChart = (
      <Chart
        ref={chartRef}
        data={chartData}
        options={chartOptions}
        onClick={onTileClick}
        type="treemap"
      />
    );
  } else if (data.total_count > 0) {
    theChart = (
      <Alert>
        {path}
        {' '}
        has no indexed children.
      </Alert>
    );
  }
  return theChart;
}

export function colorBar(props) {
  const { colorBy, data } = props;
  const items = colors;
  let max = Math.max.apply(Math, data.children.map((x) => x[colorBy]));
  switch (colorBy) {
    case 'size':
      max = prettyBytes(max);
      break;
    case 'mean_heat':
      max = humanizeDuration(max, { largest: 1 });
      break;
    case 'count':
      max = max.toLocaleString();
      break;
  }
  return (
    <tbody>
      <tr><th>0</th></tr>
      {items.map((item) => (
        <tr key={item}>
          <td style={{ backgroundColor: item }} aria-label="background color" />
        </tr>
      ))}
      <tr><th>{max}</th></tr>
    </tbody>
  );
}
