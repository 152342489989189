import React, { useEffect, useState } from 'react';
import { PageHeader } from 'fwtheme-react-jasmin';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { LoadingSpinner } from '../components/Notifications/Loading';
import { PermissionDeniedNotify } from '../components/Notifications/PermissionDenied';
import { GwsErrorNotify } from '../components/Notifications/GwsError';
import './MyGWS.css';
import { useCurrentUser } from '../api/currentUser';

export function AllGWSPage() {
  useEffect(() => {
    document.title = 'All GWS | JASMIN Group Workspace Scanner';
  }, []);
  const [isLoading, setLoading] = useState(true);
  const [catchError, setError] = useState(false);
  const [allServices, setAllServices] = useState({
    name: '', location: '',
  });
  useEffect(() => {
    try {
      const fetchAllServices = async () => {
        const result = await axios(
          '/api/service-list',
          { headers: { Accept: 'application/json' } },
        );
        setAllServices(result.data);
        setLoading(false);
      };
      fetchAllServices();
    } catch (err) {
      if (err.response) {
        setError([err.response.status, err.response.statusText]);
      } else if (err.message) {
        setError(['-', err.message]);
      } else {
        setError([' ', 'No details given.']);
      }
    }
  }, []);
  const user = useCurrentUser();
  const isAdmin = user.data.admin_status;
  if (!isAdmin) {
    return PermissionDeniedNotify();
  }
  if (catchError) {
    return GwsErrorNotify('Error loading the group workspaces.', catchError[0], catchError[1]);
  }
  return (
    <div>
      <PageHeader>All Group Workspaces</PageHeader>
      <p>
        The entire list of group workspaces are listed below.
        Click on the location to go to the tree page.
      </p>
      { isLoading ? (
        LoadingSpinner()
      ) : (
        <div className="col-lg-5 col-md-6">
          <div className="card">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th> </th>
                  <th>Service Name</th>
                  <th>Location</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {allServices.map((d) => (
                  <tr key={d.name}>
                    <td> </td>
                    <td><code>{d.name}</code></td>
                    <td><Link to={`/tree${d.location}`}>{d.location}</Link></td>
                    <td> </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
