import React, { useState } from 'react';
import prettyBytes from 'pretty-bytes';
// import humanizeDuration from 'humanize-duration';
import { Tooltip } from 'react-tooltip';

export default function TreeTable(props) {
  const { rawData } = props;
  const [orderBy, setOrderBy] = useState('');
  const sortedArr = rawData.children;

  switch (orderBy) {
    case 'size-descending':
      sortedArr.sort((a, b) => b.size - a.size);
      break;
    case 'size-ascending':
      sortedArr.sort((a, b) => a.size - b.size);
      break;
    case 'count-descending':
      sortedArr.sort((a, b) => b.count - a.count);
      break;
    case 'count-ascending':
      sortedArr.sort((a, b) => a.count - b.count);
      break;
    case 'a-z':
      sortedArr.sort((a, b) => a.path.localeCompare(b.path));
      break;
    case 'z-a':
      sortedArr.sort((a, b) => b.path.localeCompare(a.path));
      break;
    // case 'heat-descending':
    //   sortedArr.sort((a, b) => b.mean_heat - a.mean_heat);
    //   break;
    // case 'heat-ascending':
    //   sortedArr.sort((a, b) => a.mean_heat - b.mean_heat);
    //   break;
  }

  return (
    <div className="card" style={{ backgroundColor: '#ECF0F1', borderRadius: 0 }}>
      <table className="table table-hover table-">
        <thead>
          <tr>
            <th> </th>
            <th>
              Folder
              {' '}
              <select className="text-center p-0.5 border" style={{ width: '20px' }} onChange={(e) => { setOrderBy(e.target.value); }}>
                <option hidden value=""> </option>
                <option disabled value="Sort By"> Sort By</option>
                <option value="a-z">Alphabetically (A-Z)</option>
                <option value="z-a">Alphabetically (Z-A)</option>
              </select>
            </th>
            <th>
              Size
              {' '}
              <select className="text-center p-0.5 border" style={{ width: '20px' }} onChange={(e) => { setOrderBy(e.target.value); }}>
                <option hidden value=""> </option>
                <option disabled value="Sort By"> Sort By</option>
                <option value="size-ascending">Ascending</option>
                <option value="size-descending">Descending</option>
              </select>
            </th>
            <th>
              Count
              {' '}
              <select className="text-center p-0.5 border" style={{ width: '20px' }} onChange={(e) => { setOrderBy(e.target.value); }}>
                <option hidden value=""> </option>
                <option disabled value="Sort By"> Sort By</option>
                <option value="count-ascending">Ascending</option>
                <option value="count-descending">Descending</option>
              </select>
            </th>
            {/* <th>
              Mean Heat
              <select className="text-center p-0.5 border" style={{ width: '20px' }}
              onChange={(e) => { setOrderBy(e.target.value); }}>
                <option hidden value=""> </option>
                <option disabled value="Sort By"> Sort By</option>
                <option value="heat-ascending">Ascending</option>
                <option value="heat-descending">Descending</option>
              </select>
            </th> */}
          </tr>
        </thead>
        <tbody>
          <Tooltip id="path-hover" />
          {sortedArr.map((d) => (
            <tr
              key={d.path}
            >
              <td> </td>
              <td style={{
                whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '300px',
              }}
              >
                <code>
                  <span
                    data-tooltip-id="path-hover"
                    data-tooltip-content={d.path}
                  >
                    {d.path}
                  </span>
                </code>
              </td>
              <td>{prettyBytes(d.size)}</td>
              <td>{d.count}</td>
              {/* <td>{humanizeDuration(d.mean_heat,
              { round: true, units: ['y', 'mo', 'w', 'd', 'h', 'm'] })}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
