import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export function PageNotFound() {
  return (
    <div className="box-colour p-5">
      <Row>
        <Col>
          <h1>Page Not Found</h1>
          <div className="alert alert-with-icon alert-warning" role="alert">
            <i className="fas fa-exclamation-circle" />
            <span>The page you are looking for does not exist</span>
          </div>
          <p>The page you requested does not exist on this server.</p>
          <p>
            You could try returning to the
            <a href="/"> homepage </a>
            or viewing your
            <a href="/my_gws"> group workspaces </a>
          </p>
        </Col>
      </Row>
    </div>
  );
}
