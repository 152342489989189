import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import prettyBytes from 'pretty-bytes';
import { Tooltip } from 'react-tooltip';

export default function DoughnutGraph(props) {
  const { columnTitle, rawData } = props;
  const [scaleBy, setScaleBy] = useState('Size');
  const [orderBy, setOrderBy] = useState('descending');
  const sortedArr = [];

  for (const [key, value] of Object.entries(rawData)) {
    sortedArr.push({ label: key, data: value[scaleBy.toLowerCase()] });
  }
  switch (orderBy) {
    case 'descending':
      sortedArr.sort((a, b) => b.data - a.data);
      break;
    case 'ascending':
      sortedArr.sort((a, b) => a.data - b.data);
      break;
    case 'a-z':
      sortedArr.sort((a, b) => a.label.localeCompare(b.label));
      break;
    case 'z-a':
      sortedArr.sort((a, b) => b.label.localeCompare(a.label));
      break;
  }

  return (
    <>
      <ButtonGroup className="float-right">
        <Button variant={scaleBy === 'Size' ? 'success' : 'primary'} onClick={() => setScaleBy('Size')}>Size</Button>
        <Button variant={scaleBy === 'Count' ? 'success' : 'primary'} onClick={() => setScaleBy('Count')}>Count</Button>
      </ButtonGroup>
      <div className="card" style={{ backgroundColor: '#ECF0F1', borderRadius: 0 }}>
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th> </th>
              <th>
                {columnTitle}
                {' '}
                <select className="text-center p-0.5 border" style={{ width: '20px' }} onChange={(e) => { setOrderBy(e.target.value); }}>
                  <option hidden value=""> </option>
                  <option disabled value="Sort By"> Sort By</option>
                  <option value="a-z">Alphabetically (A-Z)</option>
                  <option value="z-a">Alphabetically (Z-A)</option>
                </select>
              </th>
              <th>
                {scaleBy}
                {' '}
                <select className="text-center p-0.5 border" style={{ width: '20px' }} onChange={(e) => { setOrderBy(e.target.value); }}>
                  <option hidden value=""> </option>
                  <option disabled value="Sort By"> Sort By</option>
                  <option value="ascending">Ascending</option>
                  <option value="descending">Descending</option>
                </select>
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <Tooltip id="label-hover" />
            {sortedArr.map((d) => (
              <tr key={d.label}>
                <td> </td>
                <td style={{
                  whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '200px',
                }}
                >
                  <code
                    data-tooltip-id="label-hover"
                    data-tooltip-content={d.label}
                  >
                    {d.label}
                  </code>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  { scaleBy === 'Size' ? prettyBytes(d.data) : d.data }
                </td>
                <td> </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
