import React from 'react';

export const PermissionDeniedNotify = function PermissionDeniedNotify() {
  return (
    <div className="col-lg-5">
      <div className="bs-component">
        <div className="alert alert-dismissible alert-danger">
          <button className="btn-close" type="button" data-bs-dismiss="alert"> </button>
          <strong>Permission Denied: </strong>
          You do not have permission to access this page.
        </div>
      </div>
    </div>
  );
};

export default PermissionDeniedNotify;
