import React, { useMemo, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PageHeader } from 'fwtheme-react-jasmin';
import { useCurrentUser } from '../api/currentUser';
import './Home.css';
import { LoadingSpinner } from '../components/Notifications/Loading';

function UserHome({ isAdmin }) {
  return (
    <div className="box-colour p-5">
      <PageHeader>JASMIN Group Workspace Scanner</PageHeader>
      <Row>
        <Col>
          <p>Welome to the JASMIN Group Workspace Scanner.</p>
          <p> Follow the link to view your group workspaces.</p>
          <a href="/my_gws">
            <Button variant="primary" size="lg"> My GWSs </Button>
          </a>
          <p> </p>
          {isAdmin && (
            <a href="/all_gws">
              <Button variant="primary" size="lg"> All GWSs </Button>
            </a>
          )}
        </Col>
      </Row>
    </div>
  );
}

function GuestHome() {
  return (
    <div className="box-colour p-5">
      <PageHeader>JASMIN Group Workspace Scanner</PageHeader>
      <Row>
        <Col>
          <p>Welome to the JASMIN Group Workspace Scanner.</p>
          <p> Please log in to view your group workspaces.</p>
          <a href="/api/login">
            <Button variant="primary" size="lg"> Log in </Button>
          </a>
        </Col>
      </Row>
    </div>
  );
}

export const HomePage = function HomePage() {
  useEffect(() => {
    document.title = 'Home | JASMIN Group Workspace Scanner';
  }, []);
  // Check whether to show user home or guest home
  const currentUser = useCurrentUser();
  const notAuthenticated = useMemo(
    () => (
      // Wait until there isn't a fetch in progress before checking authentication
      !currentUser.fetching
      // If there's no error or it's not an authentication error assume we are authenticated
      && currentUser.fetchError
      && ([401, 403].includes(currentUser.fetchError.status))
      && (
        currentUser.fetchError.status === 401
        || currentUser.fetchError.json().code === 'not_authenticated'
      )
    ),
    [currentUser],
  );
  // If the user is initialised assume they are logged in and check their admin status
  if (currentUser.initialised) {
    const adminStatus = currentUser.data.admin_status;
    return <UserHome isAdmin={adminStatus} />;
  }
  // Otherwise, show the guest home with a login button
  if (notAuthenticated) {
    return <GuestHome />;
  }
  // Else, return a loading spinner
  return (
    <div className="d-flex justify-content-center my-5">
      <LoadingSpinner />
    </div>
  );
};

export default HomePage;
