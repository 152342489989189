import createColormap from 'colormap';
import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import prettyBytes from 'pretty-bytes';
import _uniqueId from 'lodash/uniqueId';

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'column';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';

      li.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = `${item.lineWidth}px`;
      boxSpan.style.display = 'inline-block';
      boxSpan.style.height = '20px';
      boxSpan.style.marginRight = '10px';
      boxSpan.style.width = '20px';

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

export default function DoughnutGraph(props) {
  const [scaleBy, setScaleBy] = useState('size');
  const [id] = useState(_uniqueId('doughnut-'));
  const { data } = props;
  const rawData = data || { __data_not_loaded__: { count: 1, size: 1 } };

  const { length } = Object.keys(rawData);
  let colors = createColormap({ colormap: 'viridis', nshades: Math.max(10, length), format: 'hex' });
  colors = colors.sort(() => Math.random() - 0.5);

  const procData = [];
  const labels = [];
  const sortedArr = [];

  for (const [key, value] of Object.entries(rawData)) {
    sortedArr.push({ label: key, [scaleBy]: value[scaleBy] });
  }
  sortedArr.sort((a, b) => b[scaleBy] - a[scaleBy]);
  for (let i = 0; i < sortedArr.length; i += 1) {
    procData.push(sortedArr[i][scaleBy]);
    labels.push(sortedArr[i].label);
  }

  const chartPlugins = [htmlLegendPlugin];

  const chartData = {
    labels,
    datasets: [{
      data: procData,
      backgroundColor: colors,
    }],
  };

  const chartOptions = {
    animation: { animateScale: true },
    plugins: {
      tooltip: {
        callbacks: {
          label(item) {
            switch (scaleBy) {
              case 'size':
                return `${item.label}: ${prettyBytes(item.raw)}`;
              case 'count':
                return `${item.label}: ${item.raw.toLocaleString()}`;
            }
          },
        },
      },
      legend: {
        display: false,
      },
      htmlLegend: {
        containerID: id,
      },
    },
  };

  return (
    <>
      <ButtonGroup className="float-right">
        <Button variant={scaleBy === 'size' ? 'success' : 'primary'} onClick={() => setScaleBy('size')}>Size</Button>
        <Button variant={scaleBy === 'count' ? 'success' : 'primary'} onClick={() => setScaleBy('count')}>Count</Button>
      </ButtonGroup>
      <Doughnut data={chartData} options={chartOptions} plugins={chartPlugins} />
      <div id={id} className="d-flex flex-wrap" width="100%" />
    </>
  );
}
