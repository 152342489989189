import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Chart as JSChart, registerables } from 'chart.js';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button, ButtonGroup, Col, Row,
} from 'react-bootstrap';
import './Tree.css';
import prettyBytes from 'pretty-bytes';
import { getElementAtEvent } from 'react-chartjs-2';
import DoughnutGraph from '../components/graphs/DoughnutGraph';
import DoughnutTable from '../components/tables/DoughnutTable';
import TreeTable from '../components/tables/TreeTable';
import TreeGraph, { colorBar as TreeColorBar } from '../components/graphs/TreeGraph';
import { PermissionDeniedNotify } from '../components/Notifications/PermissionDenied';
import { LoadingSpinner } from '../components/Notifications/Loading';
import { GwsErrorNotify } from '../components/Notifications/GwsError';

JSChart.register(...registerables, TreemapController, TreemapElement);

export const TreePage = function TreePage() {
  const prefix = '/tree';
  const location_ = useLocation().pathname;
  const path = location_.slice(prefix.length);

  const navigate = useNavigate();

  const [colorBy, setColorBy] = useState('count');
  const [scaleBy, setScaleBy] = useState('size');
  const [userView, setUserView] = useState('graph');
  const [fileView, setFileView] = useState('graph');
  const [heatView, setHeatView] = useState('graph');
  const [treeView, setTreeView] = useState('graph');

  const [treeLoading, setTreeLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  const [heatLoading, setHeatLoading] = useState(true);
  const [typeLoading, setTypeLoading] = useState(true);

  const [catchError, setError] = useState(false);

  const [treeData, setTreeData] = useState({
    last_scan_date: '', children: [], path: '', total_count: 0, total_size: 0, status: 0,
  });
  useEffect(() => {
    const fetchTreeData = async () => {
      try {
        const result = await axios(
          `/api/tree${path}`,
          { headers: { Accept: 'application/json' } },
        );
        setTreeData(result.data);
        setTreeLoading(false);
      } catch (err) {
        if (err.response) {
          setError([err.response.status, err.response.statusText]);
        } else if (err.message) {
          setError(['-', err.message]);
        } else {
          setError([' ', 'No details given.']);
        }
      }
    };
    fetchTreeData();
  }, [path]);

  const [userData, setUserData] = useState({
    users: [],
  });
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const result = await axios(
          `/api/users${path}`,
          { headers: { Accept: 'application/json' } },
        );
        setUserData(result.data);
        setUserLoading(false);
      } catch (err) {
        if (err.response) {
          setError([err.response.status, err.response.statusText]);
        } else if (err.message) {
          setError(['-', err.message]);
        } else {
          setError([' ', 'No details given.']);
        }
      }
    };
    fetchUserData();
  }, [path]);

  const [typeData, setTypeData] = useState({
    filetypes: [],
  });
  useEffect(() => {
    const fetchTypeData = async () => {
      try {
        const result = await axios(
          `/api/types${path}`,
          { headers: { Accept: 'application/json' } },
        );
        setTypeData(result.data);
        setTypeLoading(false);
      } catch (err) {
        if (err.response) {
          setError([err.response.status, err.response.statusText]);
        } else if (err.message) {
          setError(['-', err.message]);
        } else {
          setError([' ', 'No details given.']);
        }
      }
    };
    fetchTypeData();
  }, [path]);

  const [heatData, setHeatData] = useState({
    heat: [],
  });
  useEffect(() => {
    const fetchHeatData = async () => {
      try {
        const result = await axios(
          `/api/heat${path}`,
          { headers: { Accept: 'application/json' } },
        );
        setHeatData(result.data);
        setHeatLoading(false);
      } catch (err) {
        if (err.response) {
          setError([err.response.status, err.response.statusText]);
        } else if (err.message) {
          setError(['-', err.message]);
        } else {
          setError([' ', 'No details given.']);
        }
      }
    };
    fetchHeatData();
  }, [path]);

  const treeChartRef = useRef();
  const navigateDown = (event) => {
    setTreeLoading(true);
    setUserLoading(true);
    setHeatLoading(true);
    setTypeLoading(true);
    const element = getElementAtEvent(treeChartRef.current, event)[0].element.$context.raw._data;
    const epath = element.path;
    if (!(epath === '__unindexed_children__') && element.count > 1) {
      navigate(`${location_}/${epath}`);
    }
  };
  function navigateUp() {
    navigate(location_.substring(0, location_.lastIndexOf('/')));
  }

  const countChildren = treeData.total_count - 1;
  const prettySize = prettyBytes(treeData.total_size);

  const gwsParts = path.split('/');
  const gws = gwsParts[gwsParts.length - 1];
  useEffect(() => {
    document.title = `${gws} | JASMIN Group Workspace Scanner`;
  }, []);

  if (catchError[0] === 401) {
    return PermissionDeniedNotify();
  }
  if (catchError) {
    return GwsErrorNotify(path, catchError[0], catchError[1]);
  }

  return (
    <>
      <Row className="my-2">
        <Col md={8}>
          <h2>{path}</h2>
        </Col>
      </Row>
      {treeLoading ? (
        LoadingSpinner()
      ) : (
        <Row>
          <Col md={10}>
            {treeView === 'graph' && (
              <div className="chart-container">
                <TreeGraph
                  path={path}
                  data={treeData}
                  scaleBy={scaleBy}
                  colorBy={colorBy}
                  chartRef={treeChartRef}
                  onTileClick={navigateDown}
                />
              </div>
            )}
            {treeView === 'table' && <TreeTable rawData={treeData} />}
          </Col>
          <Col md={2}>
            <Row>
              <p>
                Last Scanned:
                {' '}
                <code>{treeData.last_scan_date}</code>
              </p>
            </Row>
            <Row>
              <p>
                <code>{path}</code>
                {' '}
                and its
                {' '}
                {countChildren.toLocaleString()}
                {' '}
                children have a total size of
                {' '}
                {prettySize}
                .
              </p>
            </Row>
            {treeView === 'graph' && (
              <Row>
                <Button className="mb-2" onClick={navigateUp}>Go Up One Level</Button>
              </Row>
            )}
            <div>
              Show data as:
              <br />
              <ButtonGroup>
                <Button variant={treeView === 'graph' ? 'success' : 'primary'} onClick={() => setTreeView('graph')}>Graph</Button>
                <Button variant={treeView === 'table' ? 'success' : 'primary'} onClick={() => setTreeView('table')}>Table</Button>
              </ButtonGroup>
            </div>
            {treeView === 'graph' && (
              <>
                <Row>
                  <div>
                    Scale tiles by:
                    <br />
                    <ButtonGroup>
                      <Button variant={scaleBy === 'size' ? 'success' : 'primary'} onClick={() => setScaleBy('size')}>Size</Button>
                      <Button variant={scaleBy === 'count' ? 'success' : 'primary'} onClick={() => setScaleBy('count')}>Count</Button>
                      <Button variant={scaleBy === 'mean_heat' ? 'success' : 'primary'} onClick={() => setScaleBy('mean_heat')}>Heat</Button>
                    </ButtonGroup>
                  </div>
                </Row>
                <Row>
                  <div>
                    Colour tiles by:
                    <br />
                    <ButtonGroup>
                      <Button variant={colorBy === 'size' ? 'success' : 'primary'} onClick={() => setColorBy('size')}>Size</Button>
                      <Button variant={colorBy === 'count' ? 'success' : 'primary'} onClick={() => setColorBy('count')}>Count</Button>
                      <Button variant={colorBy === 'mean_heat' ? 'success' : 'primary'} onClick={() => setColorBy('mean_heat')}>Heat</Button>
                    </ButtonGroup>
                  </div>
                </Row>
                <Row className="m-2">
                  <table>
                    <TreeColorBar colorBy={colorBy} data={treeData} />
                  </table>
                </Row>
              </>
            )}
            <Row>
              <p>
                The
                <code> children </code>
                are the number of files
                and folders within the folder.
                <code> Indexed children </code>
                refers to only the folders.
                The files are lumped together as
                <code> unindexed children</code>
                . Once the max scan depth is reached, the files
                and folders are all unindexed children.
              </p>
            </Row>
          </Col>
        </Row>
      )}
      <p> </p>
      <Row>
        <Col md={4}>
          <h3>User Breakdown</h3>
          {userLoading ? (
            LoadingSpinner()
          ) : (
            <div>
              <ButtonGroup>
                <Button variant={userView === 'graph' ? 'success' : 'primary'} onClick={() => setUserView('graph')}>Graph</Button>
                <Button variant={userView === 'table' ? 'success' : 'primary'} onClick={() => setUserView('table')}>Table</Button>
              </ButtonGroup>
              {userView === 'graph' && <DoughnutGraph data={userData.users} />}
              {userView === 'table' && <DoughnutTable columnTitle="User" rawData={userData.users} />}
            </div>
          )}
        </Col>
        <Col md={4}>
          <h3>Filetype Breakdown</h3>
          {typeLoading ? (
            LoadingSpinner()
          ) : (
            <div>
              <ButtonGroup>
                <Button variant={fileView === 'graph' ? 'success' : 'primary'} onClick={() => setFileView('graph')}>Graph</Button>
                <Button variant={fileView === 'table' ? 'success' : 'primary'} onClick={() => setFileView('table')}>Table</Button>
              </ButtonGroup>
              {fileView === 'graph' && <DoughnutGraph data={typeData.filetypes} />}
              {fileView === 'table' && <DoughnutTable columnTitle="Filetype" rawData={typeData.filetypes} />}
            </div>
          )}
          <p>
            Files are categorized using file extension only
            (no attempt is made to guess a file&apos;s type from it&apos;s content).
            Mapping from filetype to mimetype uses the python mimetypes module, which uses
            {' '}
            <a href="https://pagure.io/mailcap/blob/master/f/mime.types">this</a>
            {' '}
            list amongst other data sources.
          </p>
        </Col>
        <Col md={4}>
          <h3>Heat Breakdown</h3>
          {heatLoading ? (
            LoadingSpinner()
          ) : (
            <div>
              <ButtonGroup>
                <Button variant={heatView === 'graph' ? 'success' : 'primary'} onClick={() => setHeatView('graph')}>Graph</Button>
                <Button variant={heatView === 'table' ? 'success' : 'primary'} onClick={() => setHeatView('table')}>Table</Button>
              </ButtonGroup>
              {heatView === 'graph' && <DoughnutGraph data={heatData.heat} />}
              {heatView === 'table' && <DoughnutTable columnTitle="Heat" rawData={heatData.heat} />}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default TreePage;
