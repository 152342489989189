import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';

// Throw a HTTP error detailing name, status text and response text.
class HttpError extends Error {
  constructor(status, statusText, responseText) {
    super(statusText);
    this.name = this.constructor.name;
    this.status = status;
    this.statusText = statusText;
    this.responseText = responseText;
  }

  json() {
    return JSON.parse(this.responseText);
  }
}

// Make a fetch request to the given url
const apiFetch = async (url, options) => {
  const {
    method = 'GET', headers = {}, data, body, ...rest
  } = options || {};
  const defaultHeaders = { Accept: 'application/json' };
  const response = await fetch(url, {
    method,
    headers: { ...defaultHeaders, ...headers },
    // If data is given, encode it as JSON and use it as the request body
    body: data ? JSON.stringify(data) : body,
    credentials: 'include',
    // Forward any other options that were supplied
    ...rest,
  });
  // Just return if it's a 204
  if (response.status === 204) return;
  // Any other successful response should be JSON
  if (response.ok) return response.json();
  // An error response may not be json so read the response as text
  const responseText = await response.text();
  throw new HttpError(response.status, response.statusText, responseText);
};

// A hook which defines a point at which a fetch will be triggered
const useFetchPoint = (fetchable) => {
  const { fetching, dirty, fetch } = fetchable;
  // Trigger a refetch when dirty changes to true and there isn't an active fetch
  useEffect(
    () => {
      // Check if there is anything to do and return if not
      if (fetching || !dirty) return;
      // Otherwise, excecute the fetch and return the cancel handle
      return fetch();
    },
    [dirty],
  );
  return fetchable;
};

// Returns the following methods for an endpoint: fetch, markDirty, reset
const endpointMethods = (
  url,
  setState,
  // Allow a transformation to be applied to data before it is store (default = do nothing)
  transformData = (data) => data,
  resetExtraState = {},
) => ({
  // This function fetches new data from the endpoint
  fetch: () => {
    // Set fetching to true whilst fetch is in progress
    setState((state) => ({ ...state, fetching: true }));
    // To prevent state updates after a components has unmounted, we need to be able to
    // cancel fetches so we use an abort controller.
    const controller = new AbortController();
    // Start the fetch using the signal from the controller.
    apiFetch(url, { signal: controller.signal })
      // If the promise resolves, update the state with the new data.
      .then((data) => setState((state) => ({
        ...state,
        // The data has just been fetched so its not dirty
        dirty: false,
        // Data has been fetch so we are initialised
        initialised: true,
        // The fetch is complete
        fetching: false,
        // Clear any existing error before catching an error
        fetchError: null,
        // Perform the transformation
        data: transformData(data),
      })))
      // If the promise rejects update the state with the error
      .catch((error) => {
        // If it's an abort error it's cause the component owning the data has
        // been unmounted so don't update the state
        if (error.name === 'AbortError') return;
        setState((state) => ({
          ...state,
          dirty: false,
          fetching: false,
          fetchError: error,
        }));
      });
    // Return the abort function to allow the fetch to be cancelled from outside
    return () => controller.abort();
  },
  // Mark the endpoint as dirty so the data is re-fetched
  markDirty: () => setState((state) => ({ ...state, dirty: true })),
  // Reset the endpoint to clear all the data and trigger a re-fetch
  reset: () => setState((state) => ({
    ...state,
    initialised: false,
    dirty: true,
    fetching: false,
    fetchError: null,
    data: undefined,
    ...resetExtraState,
  })),
});

// Produce the initial state for an endpoint
const endpointInitialState = ({ initialData, autoFetch = true }) => ({
  // Indicates if data has been fetched succesfully at least once
  initialised: initialData !== undefined,
  // Indicates if the data is old and needs to be re-fetched
  // An endpoint is considered dirty initially if no initial data is given and
  // autoFetch is enabled.
  dirty: autoFetch && initialData === undefined,
  // Indicates if a fetch is currently in progress
  fetching: false,
  // The error from the last fetch, is null if the fetch was successful
  fetchError: null,
  // The data from the last successful fetch
  data: initialData,
});

// Hook to fetch data from a HTTP endpoint
export const useEndpoint = (url, options) => {
  // Make a state component to hold the current endpoint state
  const [state, setState] = useState(endpointInitialState(options || {}));
  // Get the endpoint methods and use in a memo to avoid objects changing
  // If the url changes then make new methods
  const methods = useMemo(() => endpointMethods(url, setState), [url]);
  // This hook is also a fetch point for the endpoint
  return useFetchPoint({ ...state, ...methods });
};

// Turn the hook into a context so the hook state can be shared between components
const CreateContextForHook = (hookFunction) => (url, options = {}) => {
  // Make a new context
  const Context = createContext();
  return {
    // Return a provider which is an anchor for the data and fetch point
    Provider: ({ children }) => {
      const fetchable = hookFunction(url, { ...options, autoFetch: false });
      return <Context.Provider value={fetchable}>{children}</Context.Provider>;
    },
    // Return a hook which uses the context to share the hook state
    Hook: (autoFetch = true) => {
      const fetchable = useContext(Context);
      useEffect(
        () => { if (autoFetch && !fetchable.initialised) fetchable.markDirty(); },
        [],
      );
      return fetchable;
    },
  };
};

// Create a context for the useEndpoint hook
export const CreateContextForEndpoint = CreateContextForHook(useEndpoint);
// Create context for the api/user-info endpoint to get the current user
const CurrentUser = CreateContextForEndpoint('/api/user-info');
// The provider can be used to anchor the user data
const CurrentUserProvider = CurrentUser.Provider;
// The hook lets you share the current user data
export const useCurrentUser = CurrentUser.Hook;

// The user provider shares the data with the children
export const UserProvider = ({ children }) => (
  <CurrentUserProvider>
    {children}
  </CurrentUserProvider>
);
