import {
  BrowserRouter as Router, Route, Routes, useLocation,
} from 'react-router-dom';
import { Container } from 'react-bootstrap';
import React, { useEffect, useMemo } from 'react';
import Alert from 'react-bootstrap/Alert';
import { TreePage } from './pages/Tree';
import { HomePage } from './pages/Home';
import { MyGWSPage } from './pages/MyGWS';
import { AllGWSPage } from './pages/AllGWS';
import { PageNotFound } from './pages/PageNotFound';
import AppNavbar from './components/AppNavbar/AppNavbar';
import { UserProvider, useCurrentUser } from './api/currentUser';
import { LoadingSpinner } from './components/Notifications/Loading';

const AuthenticatedComponent = ({ children }) => {
  const currentUser = useCurrentUser();
  const location = useLocation();

  const notAuthenticated = useMemo(
    () => (
      // Wait until there isn't a fetch in progress before checking authentication
      !currentUser.fetching
      // If there's no error or it's not an authentication error assume we are authenticated
      && currentUser.fetchError
      && ([401, 403].includes(currentUser.fetchError.status))
      && (
        currentUser.fetchError.status === 401
        || currentUser.fetchError.json().code === 'not_authenticated'
      )
    ),
    [currentUser],
  );
  // Redirect user to login if they are not authenticated
  useEffect(
    () => {
      if (notAuthenticated) window.location = `/api/login/?next=${location.pathname}`;
    },
    [notAuthenticated, location.pathname],
  );

  if (currentUser.initialised) {
    return children;
  }
  if (notAuthenticated) {
    // Don't show anything if we are about to redirect
    return null;
  }
  if (currentUser.fetchError) {
    return <Alert variant="danger">Error fetching current user.</Alert>;
  }
  return (
    <div className="d-flex justify-content-center my-5">
      <LoadingSpinner />
    </div>
  );
};

const App = function () {
  return (
    <UserProvider>
      <AppNavbar />
      <Container fluid>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/tree/*"
              element={(
                <AuthenticatedComponent>
                  <TreePage />
                </AuthenticatedComponent>
              )}
            />
            <Route
              path="/my_gws"
              element={(
                <AuthenticatedComponent>
                  <MyGWSPage />
                </AuthenticatedComponent>
              )}
            />
            <Route
              path="/all_gws"
              element={(
                <AuthenticatedComponent>
                  <AllGWSPage />
                </AuthenticatedComponent>
              )}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </Container>
    </UserProvider>
  );
};

export default App;
