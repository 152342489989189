import React from 'react';
import { PageHeader } from 'fwtheme-react-jasmin';

export function GwsErrorNotify(path, statusCode, statusMessage) {
  document.title = 'Error | JASMIN Group Workspace Scanner';
  return (
    <div className="box-colour p-5">
      <PageHeader>
        { path }
      </PageHeader>
      <div className="col-lg-4">
        <div className="alert alert-with-icon alert-warning" role="alert">
          <i className="fas fa-exclamation-circle" />
          <span>
            There was a problem loading your group workspace
          </span>
        </div>
        <p>
          The page returned an error with status code
          {' '}
          <code>{statusCode}</code>
          {' '}
          and the response:
          {' '}
          <code>{statusMessage}</code>
        </p>
      </div>
    </div>
  );
}
