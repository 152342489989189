import React from 'react';
import ReactDOM from 'react-dom/client';
import JasminApp from 'fwtheme-react-jasmin';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <JasminApp>
      <App />
    </JasminApp>
  </React.StrictMode>,
  document.getElementById('root'),
);
