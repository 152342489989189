import React from 'react';

export const LoadingSpinner = function LoadingSpinner() {
  return (
    <>
      <p> Loading... </p>
      <div className="spinner-border text-success" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </>
  );
};

export default LoadingSpinner;
